'use client'

import { useState, useEffect } from 'react'
import { env } from 'next-runtime-env'
import { AwsRum } from 'aws-rum-web'
import { Crisp } from 'crisp-sdk-web'
import { NextFont } from 'next/dist/compiled/@next/font'
import { UserProvider } from '@auth0/nextjs-auth0/client'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LayoutProvider } from 'context/LayoutCtx'
import { NoticeProvider } from 'context/NoticeCtx'
import { type RegionsType } from 'types/regions'
import { type TenantsType } from '@consts/setupApp/tenants'
import { useAppSetupStore } from 'store/appSetup'
import getAppFont from '@theme/Fonts'
import getCustomTheme from '@theme'
import { mappTenant } from '@consts/tenants/front'

import { RatesProvider } from './rates-provider'
import { PermissionsProvider } from './permissions-provider'

const queryClient = new QueryClient()
const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) return parts.pop()?.split(';').shift()
}

export function Providers({ children }: { children: React.ReactNode }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [theme, setTheme] = useState<Record<string, any>>()
  const [appFont, setAppFont] = useState<NextFont>()
  const handleSetup = useAppSetupStore((state) => state.handleSetup)

  useEffect(() => {
    const tenant = getCookie('tenant') as TenantsType | undefined
    const region = getCookie('region')?.toLocaleLowerCase() as RegionsType | undefined

    if (!region || !tenant) return

    const initialize = async () => {
      const tenantMapped = mappTenant[tenant] || tenant
      const theme = getCustomTheme({ tenant, region })

      setTheme(theme)
      setAppFont(getAppFont(tenantMapped))
      handleSetup({ tenant })
    }

    initialize()

    const CONFIG = JSON.parse(env('NEXT_PUBLIC_CONFIG') || '{}')
    const PUBLIC_TENANTS = JSON.parse(env('NEXT_PUBLIC_TENANTS') || '{}')
    const config = CONFIG[tenant]
    const configTenant = PUBLIC_TENANTS[tenant]

    if (configTenant?.cwr) {
      const AWS_REGION = env('NEXT_PUBLIC_AWS_REGION') as string

      try {
        const awsRum: AwsRum = new AwsRum(configTenant.cwr.app_monitor_id, '1.0.0', AWS_REGION, {
          enableRumClient: true,
          sessionSampleRate: 1,
          guestRoleArn: configTenant.cwr.guest_role_arn as string,
          identityPoolId: configTenant.cwr.identity_pool_id as string,
          endpoint: `https://dataplane.rum.${AWS_REGION}.amazonaws.com`,
          telemetries: ['performance', 'errors', 'http'],
          allowCookies: true,
          enableXRay: false
        })

        awsRum.enable()
        // eslint-disable-next-line no-console
        console.log('cwr enabled')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error initializing cwr', error)
      }
    }

    if (config && config.crisp) {
      Crisp.configure(config.crisp)
      // eslint-disable-next-line no-console
      console.log('crisp enabled')
    }
  }, [])

  if (!appFont) return null

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <ColorModeScript initialColorMode={'light'} />
          <PermissionsProvider>
            <RatesProvider>
              <LayoutProvider>
                <NoticeProvider>
                  <main className={appFont.className}>{children}</main>
                </NoticeProvider>
              </LayoutProvider>
            </RatesProvider>
          </PermissionsProvider>
        </UserProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}
