'use client'

import { I18nextProvider } from 'react-i18next'
import { useEffect, useState } from 'react'
import { type TenantsType } from '@consts/setupApp/tenants'
import { type RegionsType } from 'types/regions'
import { initializeI18n } from 'i18n/i18n-config'
import { i18n } from 'i18next'

export function I18nProvider({
  children,
  locale,
  tenant
}: {
  children: React.ReactNode
  locale: RegionsType
  tenant: TenantsType
}) {
  const [i18n, setI18n] = useState<i18n | null>(null)

  useEffect(() => {
    const initialize = async () => {
      const i18nInstance = await initializeI18n(locale, tenant)

      setI18n(i18nInstance)
    }

    initialize()
  }, [locale, tenant])

  if (!i18n) return null

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
