'use client'

import { ERROR_MESSAGES } from '@consts/messages'
import React, { createContext, useState } from 'react'
import { ErrorMessages, SuccessMessages } from 'types/messages'
import { ALERT_TYPES, AlertTypes } from 'consts/alerts'
const { BADGE } = ALERT_TYPES

interface Message {
  active: boolean
  message: ErrorMessages | SuccessMessages
  messageInfo?: { [key: string]: string }
  alertType?: AlertTypes
  handleChangeAlertType: (type: AlertTypes) => void
  handleMessageInfo: (info: { [key: string]: string }) => void
  handleActive: (isActive: boolean) => void
  handleMessage: (message: ErrorMessages | SuccessMessages) => void
}

const NoticeContext = createContext<Message>({
  active: false,
  message: ERROR_MESSAGES.DEFAULT_ERROR,
  messageInfo: {},
  alertType: BADGE,
  handleChangeAlertType: () => {},
  handleMessageInfo: () => {},
  handleActive: () => {},
  handleMessage: () => {}
})

export function NoticeProvider({ children }: { children: React.ReactNode }) {
  const [active, setActive] = useState(false)
  const [alertType, setAlertType] = useState<AlertTypes>(BADGE)
  const [messageInfo, setMessageInfo] = useState<{ [key: string]: string } | undefined>()
  const [message, setMessage] = useState<ErrorMessages | SuccessMessages>(
    ERROR_MESSAGES.DEFAULT_ERROR
  )

  const handleMessageInfo = (info: { [key: string]: string }) => {
    setMessageInfo(info)
  }

  const handleActive = (isActive: boolean) => {
    setActive(isActive)
  }

  const handleMessage = (message: ErrorMessages | SuccessMessages) => {
    setMessage(message)
  }

  const handleChangeAlertType = (type: AlertTypes) => {
    setAlertType(type)
  }

  const value = {
    active,
    message,
    messageInfo,
    alertType,
    handleMessage,
    handleMessageInfo,
    handleActive,
    handleChangeAlertType
  }

  return <NoticeContext.Provider value={value}> {children} </NoticeContext.Provider>
}

export default NoticeContext
