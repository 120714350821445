import { cssVar, defineStyleConfig } from '@chakra-ui/react'

const $arrowBg = cssVar('popper-arrow-bg')

const Tooltip = defineStyleConfig({
  baseStyle: {
    color: '#FAFAFA',
    background: '#2C2930',
    fontWeight: 'normal',
    borderColor: '#2C2930',
    [$arrowBg.variable]: '#2C2930',
    borderRadius: '10px',
    fontSize: 12,
    padding: '8px 12px'
  },
  variants: {
    'rounded-bottom-left': {
      '.chakra-tooltip__arrow-wrapper': { left: '18px !important' }
    },
    'rounded-bottom-right': {
      '.chakra-tooltip__arrow-wrapper': { left: '-18px !important' }
    },
    'rounded-right': {
      '.chakra-tooltip__arrow-wrapper': { left: '-3px !important' }
    }
  }
})

export default Tooltip
