import * as React from 'react'
import { SVGProps } from 'react'
const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={51} width={50} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.732}>
      <path d="M18.75 5.12h12.5M25 21.787v8.333M25 46.787c9.205 0 16.667-7.462 16.667-16.667 0-9.205-7.462-16.666-16.667-16.666-9.205 0-16.667 7.461-16.667 16.666S15.795 46.787 25 46.787Z" />
    </g>
  </svg>
)

export default ClockIcon
