import { ThemeProviderProps } from '@chakra-ui/react'
import { theme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

import activeLabelStyles from './activeLabelStyles'

const Form = {
  variants: {
    floating: (props: ThemeProviderProps) => ({
      container: {
        color: mode('#2C2930', '#EAEAEA')(props),
        '[data-transform="true"]': { ...activeLabelStyles },
        '.chakra-input__right-element': {
          paddingRight: 4,
          width: 'auto'
        },
        _focusWithin: {
          label: {
            ...activeLabelStyles
          },
          input: {
            boxShadow: theme.shadows.none
          }
        },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
          {
            ...activeLabelStyles
          },
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          px: 2,
          my: 4,
          pl: 7,
          transformOrigin: 'left top',
          fontSize: theme.fontSizes.sm,
          color: '#A6A6A6',
          '.chakra-form__required-indicator': {
            color: '#A6A6A6'
          }
        },
        input: {
          backgroundColor: mode('#EAEAEA', '#555555')(props),
          border: theme.borders.none,
          '::placeholder': {
            color: mode('#A6A6A6', '#A6A6A6')(props),
            fontWeight: theme.fontWeights.light,
            fontSize: theme.fontSizes.sm
          },
          ':read-only': {
            color: '#A6A6A6',
            backgroundColor: mode('#EAEAEA', '#555555')(props),
            cursor: 'not-allowed'
          },
          // apply styles type date
          '&[type="date"]': {
            textAlign: 'end',
            gap: '0.5rem'
          }
        }
      }
    })
  }
}

export default Form
