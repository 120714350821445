import { useUser as useUserNextAuth } from '@auth0/nextjs-auth0/client'

export default function useUser() {
  const { user } = useUserNextAuth()

  return {
    email: user?.email,
    loading: !user,
    name: user?.name,
    nickname: user?.nickname
  }
}
