import { TenantsType } from '@consts/setupApp/tenants'

export const DEV = 'dev'
export const STG = 'stg'
export const UAT = 'uat'

export const getEnvironment = (tenant: TenantsType) => {
  const isDevelopment = tenant?.includes(DEV)
  const isStaging = tenant?.includes(STG)
  const isUat = tenant?.includes(UAT)

  if (isDevelopment) {
    return { environment: DEV, isDevelopment, isStaging: false, isUat: false }
  } else if (isStaging) {
    return { environment: STG, isStaging, isDevelopment: false, isUat: false }
  } else if (isUat) {
    return { environment: UAT, isUat, isStaging: false, isDevelopment: false }
  }

  return { environment: 'prod', isStaging: false, isDevelopment: false, isUat: false }
}
