import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const variants = {
  notifications: definePartsStyle({
    header: defineStyle({
      pl: 6,
      pr: 4,
      py: 4
    }),
    body: defineStyle({
      p: 0,
      maxH: '90vh',
      overflowY: 'auto'
    }),
    content: defineStyle({
      w: 500
    }),
    popper: defineStyle({
      borderRadius: 8
    })
  })
}

export const popoverTheme = defineMultiStyleConfig({ variants })
