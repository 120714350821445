import { ALREADY_EXISTS, CLIENT_EMAIL_MISMATCH, REQUIRED } from '@consts/Errors/fields_code'
import { initializeI18n } from 'i18n/i18n-config'
import { TenantsType } from '@consts/setupApp/tenants'
import { RegionsType } from 'types/regions'
import { FieldError, FieldsErrors } from 'types/errors'

import { getCookie } from './getCookiesClient'

const FIELDS_CODES_TRANSLATION: {
  [key: string]: {
    [key: string]: string
  }
} = {
  email: {
    [CLIENT_EMAIL_MISMATCH]: 'errors.client_email_mismatch',
    [ALREADY_EXISTS]: 'errors.email.already_exists'
  }
}

export const resolveInvalidFields = async (
  fieldsErrors: FieldsErrors
): Promise<{
  [x: string]: string
}> => {
  try {
    const tenant = getCookie('tenant')?.toLowerCase() as TenantsType
    const region = getCookie('region')?.toLowerCase() as RegionsType

    const i18nInstance = await initializeI18n(region, tenant)

    const errorsArray = Object.entries(fieldsErrors).map(([key, value]: [string, FieldError]) => {
      if (value.code === REQUIRED) {
        return {
          [key]: 'required'
        }
      } else {
        if (value.code === CLIENT_EMAIL_MISMATCH) {
          return {
            [key]: i18nInstance.t('errors.client_email_mismatch', {
              emails: value.valid_emails.join(', ')
            })
          }
        }
        if (value.code === ALREADY_EXISTS) {
          return {
            [key]: i18nInstance.t(
              FIELDS_CODES_TRANSLATION[key][ALREADY_EXISTS] || 'errorMessage.already_exists_value'
            )
          }
        }
      }

      return {
        [key]: 'required'
      }
    })
    const errorsResolved = errorsArray.reduce((acc, obj) => {
      return { ...acc, ...obj }
    }, {})

    return errorsResolved
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)

    return {}
  }
}
