import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
)

const pill = definePartsStyle({
  field: {
    pl: 6,
    pr: 6,
    pt: '12px',
    background: '#eaeaea',
    height: '48px',
    color: '#2C2930',
    _focus: {
      border: `1px solid #939393`
    },
    _dark: {
      color: '#EAEAEA',
      background: '#555'
    },
    _disabled: {
      opacity: 0.7,
      background: '#efefef'
    },
    _readOnly: {
      border: 'none',
      borderBottom: 'none',
      cursor: 'not-allowed'
    }
  }
})
const unstyled = definePartsStyle({
  field: {
    fontSize: 'sm',
    pl: 1,
    pr: 1,
    py: 1,
    borderBottom: '1px solid transparent',
    borderRadius: 0,
    _placeholder: {
      color: '#A6A6A6',
      fontSize: 'sm'
    },
    _readOnly: {
      borderBottom: 'none',
      cursor: 'not-allowed'
    },
    _hover: {
      _readOnly: {
        borderBottom: 'none',
        backgroundColor: 'transparent'
      },
      borderBottom: '1px solid transparent',
      backgroundColor: '#D2D2D2',
      borderRadius: 4
    },
    _focus: {
      borderBottom: '1px solid #939393',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomRadius: 0,
      _readOnly: {
        borderBottom: 'none'
      }
    }
  }
})

export const inputTheme = defineMultiStyleConfig({
  variants: { pill, unstyled }
})
