import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  accordionAnatomy.keys
)

const striped = definePartsStyle({
  root: {},
  button: {},
  container: {
    my: 2,
    border: 'none',
    bg: '#ededed',
    borderRadius: '5px'
  },
  panel: {},
  icon: {}
})

const accordionTheme = defineMultiStyleConfig({
  baseStyle: {
    button: {
      fontFamily: 'body'
    }
  },
  variants: { striped }
})

export default accordionTheme
