'use client'
import { Button, Flex, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import Logo from '@components/Logo'
import WindowXmark from '@components/icons/WindowXmark'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { useAppSetupStore } from 'store/appSetup'

export default function NotFound() {
  const { t } = useTranslation()
  const colors = useAppSetupStore((state) => state.colors)
  const [xsDisplay] = useMediaQuery('(max-width: 768px)')

  return (
    <Stack
      alignItems={'center'}
      bgColor={colors.background.special_background[0]}
      gap={{ base: '80px', sm: '120px' }}
      h={'100vh'}
      p={{ base: '40px', sm: '50px' }}
      w={'100vw'}
    >
      <Flex justifyContent={'center'}>
        <Logo type="dashboardLogo" />
      </Flex>
      <Stack justifyContent={'center'}>
        <Stack alignItems={'center'} gap={{ base: 7, sm: 6 }}>
          <WindowXmark color="#AAAAAA" height={64} width={64} />
          <Stack alignItems={'center'} className="cta" gap={4} justifyContent={'center'}>
            <Text
              fontSize={'xl'}
              fontWeight={500}
              maxW={{ base: '100%', sm: '400px' }}
              textAlign={'center'}
            >
              {t('errors.404.title')}
            </Text>
            <Text
              fontSize={'sm'}
              fontWeight={400}
              maxW={{ base: '100%', md: '400px' }}
              textAlign={'center'}
              whiteSpace={'normal'}
            >
              {t('errors.404.subtitle')}
            </Text>
          </Stack>
          <Button
            aria-label={'Home'}
            as={Link}
            href="/"
            px={12}
            size={xsDisplay ? 'lg' : 'xl'}
            variant="primary"
          >
            {t('buttons.backToInit')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
