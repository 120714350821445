export const MX = 'mx'
export const CL = 'cl'
export const AR = 'ar'
const REGIONS = {
  AR,
  MX,
  CL
} as const

export default REGIONS
