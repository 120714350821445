const Text = {
  baseStyle: {
    fontSize: { base: 'sm', xl: 'md' },
    textWrap: 'balance'
  },
  variants: {
    secondary: {
      opacity: 0.5
    }
  }
}

export default Text
