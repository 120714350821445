export class NotFoundError extends Error {
  constructor(message: string, public status: number) {
    super(message)
    this.status = status
    this.name = 'NotFoundError'
  }
}

export class ForbiddenError extends Error {
  constructor(message: string, public status: number) {
    super(message)
    this.status = status
    this.name = 'ForbiddenError'
  }
}

export class ValidationError extends Error {
  constructor(
    message: string,
    public errors: { [key: string]: string | undefined },
    public status: number
  ) {
    super(message)
    this.status = status
    this.name = 'ValidationError'
    this.errors = errors
  }
}
export class NotificationError extends Error {
  constructor(public code: string, public status: number) {
    super(code)
    this.code = code
    this.status = status
    this.name = 'NotificationError'
  }
}
export class UploadError extends Error {
  constructor(
    message: string,
    public errors: { [key: string]: string | undefined },
    public status: number
  ) {
    super(message)
    this.status = status
    this.name = 'UploadError'
    this.errors = errors
  }
}
export class DownloadError extends Error {
  constructor(message: string, public status: number) {
    super(message)
    this.status = status
    this.name = 'DownloadError'
  }
}
export class OperationNotAllowedError extends Error {
  constructor(
    message: string,
    public errors: { [key: string]: string | undefined },
    public status: number,
    public errorMessage: string,
    public code: string
  ) {
    super(message)
    this.status = status
    this.name = 'OperationNotAllowedError'
    this.errors = errors
    this.errorMessage = errorMessage
    this.code = code
  }
}

export class AlreadyExistsValueError extends Error {
  constructor(
    message: string,
    public errors: { [key: string]: string | undefined },
    public status: number
  ) {
    super(message)
    this.status = status
    this.name = 'AlreadyExistsValueError'
    this.errors = errors
  }
}
export class NotFoundResourceError extends Error {
  constructor(message: string, public errorMessage: string, public status: number) {
    super(message)
    this.status = status
    this.name = 'NotFoundResourceError'
    this.errorMessage = errorMessage
  }
}
