import { mode } from '@chakra-ui/theme-tools'

const Card = {
  baseStyle: {},
  container: {
    boxShadow: 'none'
  },
  header: {
    background: mode('rgba(44, 41, 48,.05)', 'rgba(44, 41, 48,.05)'),
    fontSize: '12px',
    fontWeight: 500
  },
  body: {
    background: mode('rgba(44, 41, 48,.05)', 'rgba(44, 41, 48,.05)'),
    color: mode('font', 'font')
  },
  footer: {
    background: mode('rgba(44, 41, 48,.05)', 'rgba(44, 41, 48,.05)')
  }
}

export default Card
