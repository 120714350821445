const PERSON_ID = 'person_id'
const FIRST_NAMES = 'first_names'
const LAST_NAMES = 'last_names'
const NATIONALITY = 'nationality'
const BIRTH_DATE = 'birth_date'
const PROFESSION = 'profession'
const PHONE = 'phone'
const EMAIL = 'email'
const FATHER_SURNAME = 'father_surname'
const MOTHER_SURNAME = 'mother_surname'
const RESIDENCE_TERM = 'residence_term'
const IDENTIFICATION_CARD_FILE = 'identification_card_file'

const COUNTRY_RESIDENT = 'country_resident'
const HOUSING_OWNERSHIP = 'housing_ownership'
const RENT_AMOUNT = 'rent_amount'
const STREET = 'street'
const COMPLEMENTARY = 'complementary'
const STATE = 'state'
const LOCALITY = 'locality'

const MARITAL_STATUS = 'marital_status'
const MARITAL_CONTRACT_TYPE = 'marital_contract_type'
const SPOUSE_FIRST_NAMES = 'spouse_first_names'
const SPOUSE_LAST_NAMES = 'spouse_last_names'
const SPOUSE_EMAIL = 'spouse_email'
const SPOUSE_PHONE = 'spouse_phone'
const SPOUSE_FATHER_SURNAME = 'spouse_father_surname'
const SPOUSE_MOTHER_SURNAME = 'spouse_mother_surname'
const SPOUSE_PERSON_ID = 'spouse_person_id'
const MARRIAGE_CERTIFICATE_ATTACHMENT = 'marriage_certificate_attachment'
const TYPE = 'type'

const ACADEMIC_LEVEL = 'academic_level'
const ACADEMIC_CAREER = 'academic_career'
const ACADEMIC_INSTITUTION_NAME = 'academic_institution_name'
const CERTIFICATE_FILE = 'certificate_file'
const SPOUSE_IS_CO_BORROWER = 'spouse_is_co_borrower'

const FINANCIAL_PRODUCT_TYPE = 'financial_product_type'
const PRODUCT_ID = 'product_id'

export const FIELDS_NAMES = {
  PERSON_ID,
  FIRST_NAMES,
  LAST_NAMES,
  NATIONALITY,
  BIRTH_DATE,
  PROFESSION,
  MARITAL_STATUS,
  MARITAL_CONTRACT_TYPE,
  SPOUSE_FIRST_NAMES,
  SPOUSE_LAST_NAMES,
  SPOUSE_FATHER_SURNAME,
  SPOUSE_MOTHER_SURNAME,
  TYPE,
  SPOUSE_PHONE,
  SPOUSE_EMAIL,
  SPOUSE_PERSON_ID,
  MARRIAGE_CERTIFICATE_ATTACHMENT,
  ACADEMIC_LEVEL,
  ACADEMIC_CAREER,
  ACADEMIC_INSTITUTION_NAME,
  CERTIFICATE_FILE,
  RESIDENCE_TERM,
  IDENTIFICATION_CARD_FILE,
  PHONE,
  EMAIL,
  FATHER_SURNAME,
  MOTHER_SURNAME,
  COUNTRY_RESIDENT,
  HOUSING_OWNERSHIP,
  RENT_AMOUNT,
  STREET,
  COMPLEMENTARY,
  STATE,
  LOCALITY,
  SPOUSE_IS_CO_BORROWER,
  FINANCIAL_PRODUCT_TYPE,
  PRODUCT_ID
} as const

export const LOCAL = 'local'
export const FOREIGNER = 'foreigner'

export const COUNTRY_OF_BIRTH: { [key: string]: string } = {
  [LOCAL]: 'labels.local',
  [FOREIGNER]: 'labels.foreigner'
}
