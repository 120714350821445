import { ThemeProviderProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const Checkbox = {
  baseStyle: {
    control: {
      borderRadius: '2px',
      outline: 'none',
      _focusVisible: {
        boxShadow: 'none'
      }
    }
  },
  variants: {
    primary: (props: ThemeProviderProps) => ({
      control: {
        background: 'transparent',
        border: '1.5px solid #AEAEAE',
        borderColor: '#AEAEAE'
      },
      label: {
        color: mode('font', 'font')(props),
        opacity: 0.7,
        _checked: {
          opacity: 1
        }
      }
    }),
    faded: () => ({
      control: {
        borderColor: 'font',
        opacity: 0.3,
        background: 'transparent'
      },
      label: {
        color: 'font',
        opacity: 0.3
      },
      icon: {
        color: 'font',
        opacity: 0.3
      }
    }),
    bold: () => ({
      control: {
        borderColor: 'font',
        background: 'transparent'
      },
      label: {
        color: 'font'
      },
      icon: {
        color: 'font'
      }
    })
  }
}

export default Checkbox
