import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = {
  _placeholder: {
    color: '#A6A6A6',
    fontSize: 'sm'
  }
}

const primary = defineStyle({
  borderRadius: '16px',
  fontWeight: '400',
  color: '#2C2930',
  background: '#eaeaea',
  fontSize: 'sm',
  _dark: {
    color: '#EAEAEA',
    background: '#555'
  },
  _disabled: {
    opacity: 0.7,
    background: '#efefef'
  }
})

export const TextArea = defineStyleConfig({
  baseStyle,
  variants: { primary }
})
