import { MAF } from '@consts/setupApp/tenants'
import {
  CASANUESTRA_SIZES,
  CBANK_SIZES,
  COOPEUCH_SIZES,
  DEFAULT_APP_SIZES,
  HSP_SIZES,
  MYV_SIZES,
  SBANK_SIZES,
  SUPV_SIZES,
  MAF_SIZES
} from '@consts/layoutConfig/logo'

interface Size {
  width?: number
  height?: number
}

interface TenantSizes {
  dashboardLogo: { desktop: Size; mobile: Size }
  detailLogo: { desktop: Size; mobile: Size }
}

const tenantSizes: { [key: string]: TenantSizes } = {
  cbank: CBANK_SIZES,
  hsp: HSP_SIZES,
  hsp_dev: HSP_SIZES,
  prescientsecurity1: SBANK_SIZES,
  coopeuch: COOPEUCH_SIZES,
  myv: MYV_SIZES,
  casanuestra: CASANUESTRA_SIZES,
  casanuestra_dev: CASANUESTRA_SIZES,
  supv: SUPV_SIZES,
  [MAF]: MAF_SIZES
}

export default function getLogoSizes(tenant: string) {
  return tenantSizes[tenant] || DEFAULT_APP_SIZES
}
