import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys)

const Tag = defineMultiStyleConfig({
  baseStyle: {}
})

export default Tag
