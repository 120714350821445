import { Button, Flex, Text } from '@chakra-ui/react'
import ClockIcon from '@components/icons/ClockIcon'
import { useAppSetupStore } from 'store/appSetup'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/navigation'

interface Props {
  error?: Error & { digest?: string }
  pathBack?: string
  reset?: () => void
}

const translations = {
  unauthorized: {
    title: 'errorMessage.403.title',
    subtitle: 'errorMessage.403.subtitle'
  }
}

export default function Error({ pathBack, error }: Props) {
  const { t } = useTranslation()
  const { back } = useRouter()
  const colors = useAppSetupStore((state) => state.colors)
  const messages = translations[error?.message as keyof typeof translations]

  return (
    <Flex alignItems={'center'} direction={'column'} pt={'20vh'}>
      <ClockIcon color={colors.error[500]} />
      {messages && (
        <>
          <Text
            fontSize={{ base: 'xl', xl: 'xxl' }}
            fontWeight={500}
            maxWidth={{ sm: '100%', lg: '600px' }}
            mt={{ base: 4, xl: 6 }}
            textAlign={'center'}
          >
            {t(messages.title)}
          </Text>
          <Text
            fontSize={{ base: 'xl', xl: 'xxl' }}
            fontWeight={500}
            maxWidth={{ sm: '100%', lg: '600px' }}
            mt={{ base: 4, xl: 6 }}
            textAlign={'center'}
          >
            {t(messages.subtitle)}
          </Text>
        </>
      )}
      {!messages && (
        <Text
          fontSize={{ base: 'xl', xl: 'xxl' }}
          fontWeight={500}
          maxWidth={{ sm: '100%', lg: '600px' }}
          mt={{ base: 4, xl: 6 }}
          textAlign={'center'}
        >
          {t('errorMessage.500.subtitle')}
        </Text>
      )}
      {pathBack ? (
        <Button
          as={Link}
          href={pathBack}
          mt={{ base: 6 }}
          px={{ base: 12 }}
          size="lg"
          variant="primary"
        >
          {t('buttons.return')}
        </Button>
      ) : (
        <Button mt={{ base: 6 }} px={{ base: 12 }} size="lg" variant="primary" onClick={back}>
          {t('buttons.return')}
        </Button>
      )}
    </Flex>
  )
}
