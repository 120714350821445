'use client'

import { Badge, Flex, useMediaQuery } from '@chakra-ui/react'
import { TenantsType } from '@consts/setupApp/tenants'
import { mappTenant } from '@consts/tenants/front'
import { getEnvironment } from '@utils/getEnvironment'
import Image from 'next/image'
import { useMemo } from 'react'
import { useAppSetupStore } from 'store/appSetup'
import getLogoSizes from 'theme/Logo/sizes'

export default function Logo({ type }: { type: 'dashboardLogo' | 'detailLogo' }) {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)')
  const tenant = useAppSetupStore((state) => state.tenant)
  const tenantMapped = mappTenant[tenant] || tenant
  const sizes = useMemo(() => getLogoSizes(tenantMapped), [tenantMapped])
  const key = isLargerThan900 ? 'desktop' : 'mobile'
  const { isDevelopment, isStaging, environment, isUat } = getEnvironment(tenant as TenantsType)
  const height = sizes[type][key].height || 25
  const width = sizes[type][key].width || 100

  return (
    <Flex alignItems={'end'} gap={2}>
      <Image
        alt={`${tenantMapped} logo`}
        height={height}
        src={`/images/${tenantMapped}/logo.svg`}
        width={width}
      />
      {isDevelopment && <Badge colorScheme={'blue'}>{environment}</Badge>}
      {isStaging && <Badge colorScheme={'green'}>{environment}</Badge>}
      {isUat && <Badge colorScheme={'cyan'}>{environment}</Badge>}
    </Flex>
  )
}
