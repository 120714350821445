// BASE
export const REPORTS = 'reports'

// REPORTS
export const COMMERCIAL = 'commercial'
export const OPERATIONAL = 'operational'
export const CONVERSION = 'conversion'

// RISK ROUTES
const PERSONAL_DATA = 'personal_data'
const CREDIT = 'credit'
const INCOME = 'income'
const DEBT = 'debt'
const ASSETS = 'assets'
const SUMMARY = 'summary'
const CO_APPLICANTS = 'co_applicants'
const DOCUMENTATION = 'documentation'
const POLICIES = 'policies'
const DECLARATIVE = 'declarative'
const PROPERTY = 'property'

export const ROUTES_SPANISH = {
  [PERSONAL_DATA]: 'datos_personales',
  [CO_APPLICANTS]: 'co_aplicantes',
  [CREDIT]: 'credito',
  [PROPERTY]: 'inmueble',
  [INCOME]: 'ingresos',
  [DEBT]: 'deuda',
  [ASSETS]: 'patrimonio',
  [SUMMARY]: 'resumen',
  [DOCUMENTATION]: 'documentacion',
  [POLICIES]: 'politicas',
  [DECLARATIVE]: 'datos_declarativos',
  [REPORTS]: 'reportes',
  [COMMERCIAL]: 'comercial',
  [OPERATIONAL]: 'operacionales',
  [CONVERSION]: 'conversion'
} as const
