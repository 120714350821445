import { defineStyleConfig } from '@chakra-ui/react'

const badgeTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: 5
  },
  sizes: {
    sm: {
      fontSize: 'xs',
      lineHeight: '100%',
      px: 4,
      py: 2
    },
    xs: {
      fontSize: 'xs',
      lineHeight: '100%',
      px: 3,
      py: 1
    }
  }
})

export default badgeTheme
