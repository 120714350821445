import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys
)

const baseStyle = definePartsStyle({
  color: '#2C2930',
  field: {
    height: '48px',
    background: '#EAEAEA',
    _dark: {
      color: '#EAEAEA',
      background: '#555'
    },
    _disabled: {
      opacity: 0.7,
      cursor: 'not-allowed'
    },
    _readOnly: {
      border: 'none'
    }
  },
  icon: {
    width: '18px',
    height: '18px',
    color: '#2C2930',
    _dark: {
      color: '#EAEAEA'
    },
    _disabled: {
      opacity: 0.7
    }
  }
})

const md = defineStyle({
  // fontSize: 'lg',
  // px: '4',
  border: '0px',
  h: '48px',
  pl: 6,
  pt: '12px'
})

// const mdIcon = defineStyle({
//   border: '0px',
//   h: '16px',
//   w: '16px'
// })

const sizes = {
  md: definePartsStyle({ field: md })
}

const pill = definePartsStyle({
  field: {
    border: 'none',
    height: '48px'
  }
})

const unstyled = definePartsStyle({
  field: {
    py: 1,
    fontSize: 'sm',
    border: 'none',
    backgroundColor: 'transparent',
    _hover: {
      background: '#D2D2D2'
    }
  }
})

const search = definePartsStyle({
  field: {
    fontSize: '14px',
    background: '#F2F2F2',
    pt: 4,
    pr: 0,
    pb: 4,
    pl: 4,
    _hover: {
      background: 'transparent'
    },
    _focus: {
      background: 'transparent'
    }
  },
  icon: {
    right: 0.5,
    h: '16px',
    w: '16px'
  }
})

const line = definePartsStyle({
  field: {
    fontSize: { base: 'sm', xl: 'md' },
    background: 'transparent',
    padding: 0,
    borderBlockEnd: '1px solid rgba(217, 217, 217, 1)',
    borderRadius: 0,
    _disabled: {
      cursor: 'not-allowed',
      opacity: 0.7
    },
    _hover: {
      cursor: 'pointer'
    }
  },
  icon: {
    opacity: 0.9,
    right: -1,
    width: '16px !important',
    height: '16px !important'
  }
})

const selectTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: { pill, unstyled, search, line }
})

export default selectTheme
