import { Palette } from 'types/colors'

export const CBANK_COLORS = {
  primary: {
    '200': '#4569ed',
    '300': '#4569ed',
    '400': '#3a62f2',
    '500': '#1F4CF1',
    '600': '#1F4CF1',
    '700': '#0a3bf0',
    contrast: '#fff'
  },
  secondary: {
    '200': '#4569ed',
    '300': '#4569ed',
    '400': '#3a62f2',
    '500': '#1F4CF1',
    '600': '#1F4CF1',
    '700': '#0a3bf0',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FE4849' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#B7D4FF'] }
}

export const DBANK_COLORS = {
  primary: {
    '200': '#F05164',
    '300': '#F73D4E',
    '400': '#E8344C',
    '500': '#D52B45',
    '600': '#C8243D',
    '700': '#B91731',
    contrast: '#fff'
  },
  secondary: {
    '200': '#F03F31',
    '300': '#E13531',
    '400': '#CF2B2B',
    '500': '#C22424',
    '600': '#B31718 ',
    '700': '#791111',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FE4849' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#F6E7E9'] }
}

export const SBANK_COLORS = {
  primary: {
    '200': '#f5a74c',
    '300': '#f5a74c',
    '400': '#fa9d2f',
    '500': '#FF8F0B ',
    '600': '#FF8F0B ',
    '700': '#c46d06',
    contrast: '#fff'
  },
  secondary: {
    '200': '#f5a74c',
    '300': '#f5a74c',
    '400': '#fa9d2f',
    '500': '#FF8F0B ',
    '600': '#FF8F0B ',
    '700': '#c46d06',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FE4849' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#B7D4FF'] }
}

export const DEFAULT_APP_COLORS: Palette = {
  primary: {
    '200': '#8c8c8c',
    '300': '#999999',
    '400': '#a6a6a6',
    '500': '#e6427a',
    '600': '#bfbfbf',
    '700': '#cccccc',
    contrast: '#fff'
  },
  secondary: {
    '200': '#8c8c8c',
    '300': '#999999',
    '400': '#a6a6a6',
    '500': '#b2b2b2',
    '600': '#bfbfbf',
    '700': '#cccccc',
    contrast: '#202945'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FE4849' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#B7D4FF'] }
}

export const HSP_COLORS = {
  primary: {
    '200': '#8c8c8c',
    '300': '#d4dcff',
    '400': '#4d487b',
    '500': '#211B5B',
    '600': '#151c40',
    '700': '#17123f',
    contrast: '#fff'
  },
  secondary: {
    '200': '#ffcc80',
    '300': '#ffcc80',
    '400': '#FAB0B9',
    '500': '#FF6E00',
    '600': '#ef6c00',
    '700': '#525050',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FE4849' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#B7D4FF'] }
}

export const MYV_COLORS = {
  primary: {
    '200': '#f79b74',
    '300': '#f5895b',
    '400': '#f5733b',
    '500': '#eb5f1c ',
    '600': '#f75e1b',
    '700': '#f75e1b',
    contrast: '#fff'
  },
  secondary: {
    '200': '#f79b74',
    '300': '#f5895b',
    '400': '#f5733b',
    '500': '#eb5f1c ',
    '600': '#f75e1b',
    '700': '#f75e1b',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FE4849' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#B7D4FF'] }
}

export const CASANUESTRA_COLORS = {
  secondary: {
    '200': '#00CFEC',
    '300': '#00B9D6',
    '400': '#00A3C0',
    '500': '#008DAA',
    '600': '#007094',
    '700': '#005E78',
    contrast: '#fff'
  },
  primary: {
    '200': '#FFC2D6',
    '300': '#FF82A9',
    '400': '#FF527D',
    '500': '#D70C3D',
    '600': '#A8002F',
    '700': '#7F0025',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FE4849' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#B7D4FF'] }
}

export const SUPERVIELLE_COLORS = {
  primary: {
    '200': '#B10F36',
    '300': '#B10F36',
    '400': '#B10F36',
    '500': '#B10F36',
    '600': '#B10F36',
    '700': '#B10F36',
    contrast: '#fff'
  },
  secondary: {
    '200': '#B10F36',
    '300': '#B10F36',
    '400': '#B10F36',
    '500': '#B10F36',
    '600': '#B10F36',
    '700': '#B10F36',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FF3B47' },
  background: {
    main: '#FAFAFA',
    special_background: ['#F9F8FF']
  }
}

export const DEMO_AR_COLORS = {
  primary: {
    '300': '#E1F5FC',
    '200': '#81D3F4',
    '400': '#2CB5ED',
    '500': '#0099DC',
    '600': '#0076B5',
    '700': '#005793',
    contrast: '#fff'
  },
  secondary: {
    '200': '#9DA6DC',
    '300': '#7683CE',
    '400': '#5867C4',
    '500': '#3A4CB9',
    '600': '#3444AF',
    '700': '#2A3AA3',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FF3B47' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#F1F1F1'] }
}

export const MAF_COLORS = {
  primary: {
    '200': '#FFEBEF',
    '300': '#EF999D',
    '400': '#F04F55',
    '500': '#EB0A1E',
    '600': '#C7202E',
    '700': '#B91122',
    contrast: '#fff'
  },
  secondary: {
    '200': '#EBEBEB',
    '300': '#DBDBDB',
    '400': '#B8B8B8',
    '500': '#989898',
    '600': '#707070',
    '700': '#5C5C5C',
    contrast: '#fff'
  },
  font: '#2C2930',
  success: { '400': '#588C71', '500': '#D2F8DF' },
  error: { '400': '#F56072', '500': '#FF3B47' },
  background: { main: '#FAFAFA', special_background: ['#F9F8FF', '#F1F1F1'] }
}
