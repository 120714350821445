import { LoanResponse } from 'types/Application/sections/debt'
export const LOAN_FIELDS: { [key: string]: keyof LoanResponse } = {
  id: 'id',
  balance: 'balance',
  monthly_payment: 'monthly_payment',
  number_installments_paid: 'number_installments_paid',
  total_number_installments: 'total_number_installments',
  type: 'type',
  file_attachment: 'file_attachment',
  file_attachments: 'file_attachments'
} as const
