import { ThemeProviderProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const Button = {
  baseStyle: {
    color: 'secondary.contrast'
  },
  variants: {
    primary: (props: ThemeProviderProps) => ({
      fontSize: { base: '14px', xl: '16px' },
      backgroundColor: mode('primary.500', 'primary.500')(props),
      _disabled: {
        backgroundColor: mode('#3C3C3C', '#3C3C3C')(props)
      },
      _hover: {
        backgroundColor: mode('primary.600', 'primary.600')(props),
        _disabled: {
          backgroundColor: mode('#3C3C3C', '#3C3C3C')(props)
        }
      },
      color: mode('primary.contrast', 'primary.contrast')(props)
    }),
    white: (props: ThemeProviderProps) => ({
      fontSize: { base: '14px', xl: '16px' },
      background: mode('#FFFFFF', '#FFFFFF')(props),
      color: mode('#000000', '#000000')(props),
      _hover: {
        backgroundColor: mode('#cecece', '#cecece')(props)
      }
    }),
    outline: (props: ThemeProviderProps) => ({
      fontSize: { base: '14px', xl: '16px' },
      background: mode('transparent', 'transparent')(props),
      width: 'fit-content',
      padding: '0 16px',
      fontWeight: 500,
      borderColor: mode('font', 'font')(props),
      borderWidth: '1px',
      color: mode('font', 'font')(props),
      _hover: {
        background: mode('transparent', 'transparent')(props)
      },
      _active: {
        background: mode('#fafafa', '#fafafa')(props)
      }
    }),
    chip: (props: ThemeProviderProps) => ({
      textTransform: 'uppercase',
      userSelect: 'none',
      fontWeight: 400,
      fontSize: 14,
      bg: mode('transparent', '#555555')(props),
      color: mode('#2C2930', '#EAEAEA')(props),
      border: mode('2px solid', 'none')(props)
    }),
    'soft-outline': (props: ThemeProviderProps) => ({
      fontSize: { base: '14px', xl: '16px' },
      background: mode('transparent', 'transparent')(props),
      width: 'fit-content',
      padding: '0 16px',
      fontWeight: 500,
      borderColor: mode('DADADA', 'DADADA')(props),
      borderWidth: '1px',
      color: mode('font', 'font')(props),
      _hover: {
        background: mode('transparent', 'transparent')(props)
      },
      _active: {
        background: mode('#fafafa', '#fafafa')(props)
      }
    }),
    link: (props: ThemeProviderProps) => ({
      fontSize: { base: '14px', xl: '16px' },
      color: mode('primary.500', 'primary.500')(props)
    })
  },
  sizes: {
    sm: {
      height: '28px',
      fontSize: '12px !important'
    },
    md: {
      height: '36px'
    },
    xl: {
      height: '60px',
      minWidth: '235px'
    }
  }
}

export default Button
