import { SVGProps } from 'react'
const WindowXmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={64}
    stroke="#AAA"
    width={64}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="inherit" strokeLinecap="round" strokeWidth={3}>
      <path d="M34.666 56.005h-24a5.333 5.333 0 0 1-5.333-5.334V13.338a5.333 5.333 0 0 1 5.333-5.333h42.667a5.333 5.333 0 0 1 5.333 5.333v24" />
      <path
        d="M5.333 18.671h53.333M13.333 13.365l.027-.03M21.333 13.365l.027-.03M29.333 13.365l.027-.03M53.664 53.654a8 8 0 1 0-11.328-11.3 8 8 0 0 0 11.328 11.3Zm0 0 5.003 5.017"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default WindowXmark
