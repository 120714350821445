import { fetchData } from 'repositories/fetchData'

interface Props {
  correlationId?: string
}

export async function getPermissions({ correlationId }: Props = {}): Promise<string[]> {
  try {
    const BASE = `/api/auth/permissions`
    const url = correlationId ? `${BASE}?correlationId=${correlationId}` : BASE
    const data = await fetchData({ url })

    return data.permissions
  } catch (error) {
    throw error
  }
}
