export const FONT_SIZE_BUTTON_DEFAULT_CL = {
  base: 'lg',
  md: 'sm'
}
export const FONT_SIZE_BUTTON_DEFAULT_AR = {
  base: 'lg',
  md: 'sm'
}
// import { SUPV } from 'consts/tenants'

// const FONT_SIZES = {
//   [SUPV]: {
//     base: 'sm',
//     md: 'sm'
//   }
// }

// const DEFAULT_REGION = {
//   CL: {
//     ...FONT_SIZE_BUTTON_DEFAULT_CL
//   },
//   AR: {
//     ...FONT_SIZE_BUTTON_DEFAULT_AR
//   }
// }

// export const fontSizeButton =
//   FONT_SIZES[APP_TENANT as keyof typeof FONT_SIZES] ||
//   DEFAULT_REGION[REGION as keyof typeof DEFAULT_REGION]
