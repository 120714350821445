export const CBANK_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 140,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 100,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  }
}
export const DEFAULT_APP_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 140,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 100,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  }
}
export const SBANK_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 140,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 100,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  }
}
export const COOPEUCH_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 250,
      height: undefined
    },
    mobile: {
      width: 190,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 140,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  }
}
export const CASANUESTRA_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 140,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 140,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  }
}
export const HSP_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 250,
      height: undefined
    },
    mobile: {
      width: 190,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 150,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  }
}

export const MYV_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 127,
      height: undefined
    },
    mobile: {
      width: 90,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 95,
      height: undefined
    },
    mobile: {
      width: 95,
      height: undefined
    }
  }
}
export const SUPV_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 160,
      height: undefined
    },
    mobile: {
      width: 100,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 120,
      height: undefined
    },
    mobile: {
      width: 80,
      height: undefined
    }
  }
}
export const MAF_SIZES = {
  dashboardLogo: {
    desktop: {
      width: 40,
      height: undefined
    },
    mobile: {
      width: 100,
      height: undefined
    }
  },
  detailLogo: {
    desktop: {
      width: 40,
      height: undefined
    },
    mobile: {
      width: 40,
      height: undefined
    }
  }
}
