import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { DEFAULT_APP_COLORS } from '@consts/layoutConfig/colors'
import { ConfigApp, TenantsType } from '@consts/setupApp/tenants'
import getColors from 'theme/Colors'
import { Palette } from 'types/colors'
import { env } from 'next-runtime-env'
import { type RegionsType } from 'types/regions'
import getBaseStyles from '@theme/config/baseStyles'

interface State {
  tenant: TenantsType | ''
  colors: Palette
  config?: ConfigApp
  handleSetup: ({ tenant }: { tenant: TenantsType }) => void
}

export const useAppSetupStore = create<State>()(
  devtools((set) => {
    return {
      tenant: '',
      colors: DEFAULT_APP_COLORS,
      config: undefined,

      handleSetup: ({ tenant }) => {
        const colors = getColors(tenant)
        const CONFIG = JSON.parse(env('NEXT_PUBLIC_CONFIG') || '{}') as Record<string, ConfigApp>

        set(
          {
            tenant,
            colors,
            config: {
              ...CONFIG[tenant],
              baseStyles: getBaseStyles(CONFIG[tenant]?.region.toLowerCase() as RegionsType)
            }
          },
          false,
          'HANDLE_SETUP'
        )
      }
    }
  })
)
