import { theme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { ThemeProviderProps } from '@chakra-ui/react'

import activeLabelStyles from './activeLabelStyles'

const Menu = {
  variants: {
    floating: (props: ThemeProviderProps) => ({
      button: {
        height: '60px',
        py: theme.space[2],
        px: theme.space[4],
        paddingInlineStart: theme.space[6],
        border: '2px solid #EAEAEA',
        background: mode('white', 'white')(props),
        borderRadius: 9999,
        _active: {
          span: {
            label: {
              ...activeLabelStyles
            }
          }
        },
        span: {
          label: {
            mr: theme.space[5],
            transitionProperty: theme.transition.property.common,
            transitionDuration: theme.transition.duration.normal,
            transformOrigin: 'left top'
          },
          span: {
            fontWeight: theme.fontWeights.black,
            color: '#211B5B'
          },
          fontSize: theme.fontSizes.sm,
          color: '#A6A6A6',
          display: 'grid',
          gridTemplateColumns: 'auto auto 1fr',
          justifyItems: 'flex-end'
        }
      },
      list: {
        zIndex: 999,
        color: mode('#2C2930', '#2C2930')(props)
      }
    })
  }
}

export default Menu
