// mover a la carpeta de componentes compartidos
import { useTranslation } from 'react-i18next'
import { Modal, ModalOverlay, ModalContent, Button, Text, Flex, Stack } from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
  title?: string
  alertText: string
  buttonConfirmText?: string
  buttonCancelText?: string
  withPrimaryButton?: boolean
  withSecondaryButton?: boolean
  loading?: boolean
  icon?: JSX.Element
}

export default function ModalAlert(props: Props) {
  const {
    isOpen,
    title = 'attention!',
    alertText,
    buttonConfirmText = 'buttons.confirmation',
    buttonCancelText = 'buttons.cancel',
    loading = false,
    icon,
    withPrimaryButton = true,
    withSecondaryButton = true,
    onClose,
    onConfirm
  } = props
  const { t } = useTranslation()

  return (
    <Modal
      isCentered
      closeOnEsc={!loading}
      closeOnOverlayClick={!loading}
      isOpen={isOpen}
      variant={'alert'}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <Stack gap={{ base: 4, xl: 6 }}>
          <Text fontSize={'sm'} textAlign={'center'}>
            {t(title)}
          </Text>
          {icon && <Flex justifyContent="center">{icon}</Flex>}
          <Text fontSize={{ base: 'md', xl: 'lg' }} textAlign={'center'} whiteSpace={'pre-line'}>
            {t(alertText)}
          </Text>
          <Flex gap={1} justifyContent="center">
            {withPrimaryButton && (
              <Button
                h={{ base: 12, xl: 14 }}
                isLoading={loading}
                variant="outline"
                w={{ base: 170, xl: 255 }}
                onClick={onConfirm}
              >
                {t(buttonConfirmText)}
              </Button>
            )}
            {withSecondaryButton && (
              <Button
                h={{ base: 12, xl: 14 }}
                isDisabled={loading}
                variant="primary"
                w={{ base: 170, xl: 255 }}
                onClick={onClose}
              >
                {t(buttonCancelText)}
              </Button>
            )}
          </Flex>
        </Stack>
      </ModalContent>
    </Modal>
  )
}
