/* eslint-disable no-console */

import { createInstance } from 'i18next'
import { initReactI18next } from 'react-i18next/initReactI18next'
import { type RegionsType } from 'types/regions'
import { type TenantsType } from '@consts/setupApp/tenants'
import { mappTenant } from '@consts/tenants/front'

import base from './texts/base.json'

export const initializeI18n = async (region: RegionsType, tenant: TenantsType) => {
  const tenantTranslation = mappTenant[tenant] || tenant
  const i18nInstance = createInstance()

  await i18nInstance.use(initReactI18next).init({
    lng: 'es',
    fallbackLng: 'es',
    resources: {
      es: {
        translation: base
      }
    },
    interpolation: {
      escapeValue: false
    }
  })

  try {
    // Load region translations
    const regionTranslations = await import(`./texts/${region.toUpperCase()}/base.json`)

    i18nInstance.addResourceBundle('es', 'translation', regionTranslations.default, true, true)

    // Load tenant translations
    const tenantTranslations = await import(
      `./texts/${region.toUpperCase()}/${tenantTranslation}.json`
    )

    i18nInstance.addResourceBundle('es', 'translation', tenantTranslations.default, true, true)
  } catch (err) {
    // console.error('Error initializing i18next:', err)
  }

  return i18nInstance
}
