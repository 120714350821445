import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  dialog: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  }
})
const notifications = definePartsStyle({
  dialog: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  header: {
    px: 6,
    pt: 8,
    pb: 4,
    borderBottom: '1px solid #E6E6E6'
  },
  body: {
    pt: 0,
    px: 0,
    pb: 6
  }
})

const drawerTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    notifications
  }
})

export default drawerTheme
