export const ALREADY_EXISTS_VALUE = 'ALREADY_EXISTS_VALUE' as const
export const CONTENT_FILE_NOT_ALLOWED = 'CONTENT_FILE_NOT_ALLOWED' as const
export const BLOCKED_CREDENTIALS_ERROR = 'BLOCKED_CREDENTIALS_ERROR' as const
export const USER_NOT_REGISTERED_ERROR = 'USER_NOT_REGISTERED_ERROR' as const
export const STATUS_TRANSITION_NOT_ALLOWED = 'STATUS_TRANSITION_NOT_ALLOWED' as const
export const APPLICATION_GROUP_NOT_FOUND = 'APPLICATION_GROUP_NOT_FOUND' as const
export const EMAIL_SEND_FAILED = 'EMAIL_SEND_FAILED' as const
export const ENTITY_VERSION_MISMATCH = 'ENTITY_VERSION_MISMATCH' as const
export const DESEREALIZED_DATA = 'DESEREALIZED_DATA' as const
export const VALIDATION_FIELD = 'VALIDATION_FIELD' as const
export const INVALID_FIELDS = 'INVALID_FIELDS' as const
export const CLIENT_ACTION_NOT_ALLOWED = 'CLIENT_ACTION_NOT_ALLOWED' as const
